const serviceInvoiceModalityData = [
    {
        label: "Inclusos (ISS+PIS+COFINS)",
        value: "IMPOSTOS_INCLUSOS"
    },
    {
        label: "Cobrar Impostos (ISS+PIS+COFINS)",
        value: "COBRAR_IMPOSTOS"
    },
];

export default serviceInvoiceModalityData;