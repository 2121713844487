import React from "react";
import { ErrorMessage, Field } from "formik";
import Select from "react-select";
import { InputCurrency } from "../../../../components/Input/InputCurrency";
import { InputPercentage } from "../../../../components/Input/InputPercentage";
import financialDetailsData from "../../forms/financialDetailsData";
import paymentConditionInvoicingData from "../../forms/paymentConditionInvoicingData";
import serviceInvoiceModalityData from "../../forms/serviceInvoiceModalityData";
import { exporterPaymentData } from "../../forms/exporterPaymentData";
import financialChargesModalityData from "../../forms/financialChargesModalityData";

const FinancialDataForm = ({ values, setFieldValue, handleChange }) => {
    return (
        <div>
            <div className="overflow-auto h-70vh w-full pr-4">
                <div className="flex border-b border-C2 pb-2 mt-5 items-center">
                    <p className="text-C7 font-bold">Adiantamento / Arras / Warranty</p>
                </div>
                <div className="flex gap-4 mt-8">
                    <div className="w-2/4">
                        <label className="text-roxo_oficial font-bold">Adiantamento / Arras / Warranty</label>
                        <Select
                            options={financialDetailsData}
                            name="financial.upFrontPaymentWarranty"
                            className="outline-none transform-none text-black"
                            value={financialDetailsData.find(
                                ({ value }) => value === values.financial.upFrontPaymentWarranty
                            )}
                            placeholder="Selecione..."
                            onChange={value => setFieldValue("financial.upFrontPaymentWarranty", value.value)}
                        />
                        <ErrorMessage
                            component="p"
                            name="financial.upFrontPaymentWarranty"
                            className="text-red text-xs font-light w-full"
                        />
                    </div>
                    <div className="w-2/4">
                        <label
                            htmlFor="upFrontPaymentWarrantyAdditionalInformation"
                            className="flex text-roxo_oficial gap-1 font-bold">
                            Observações <span className="text-C5 font-normal ">(opcional)</span>
                        </label>
                        <Field
                            as="textarea"
                            rows="4"
                            className="w-full rounded-md border py-2 px-2 border-C2 mt-1"
                            name="financial.upFrontPaymentWarrantyAdditionalInformation"
                            id="upFrontPaymentWarrantyAdditionalInformation"
                            onChange={handleChange}
                            value={values.financial.upFrontPaymentWarrantyAdditionalInformation}
                        />
                    </div>
                </div>

                <div className="flex border-b border-C2 pb-2 mt-5 items-center">
                    <p className="text-C7 font-bold">Desconto comercial</p>
                </div>
                <label className="text-roxo_oficial font-bold mt-4">Aplicar desconto comercial?</label>
                <Select
                    options={financialDetailsData}
                    name="financial.commercialDiscount"
                    className="outline-none transform-none text-black"
                    value={financialDetailsData.find(({ value }) => value === values.financial.commercialDiscount)}
                    placeholder="Selecione..."
                    onChange={value => setFieldValue("financial.commercialDiscount", value.value)}
                />
                <ErrorMessage
                    component="p"
                    name="financial.commercialDiscount"
                    className="text-red text-xs font-light w-full"
                />
                {values.financial.commercialDiscount === true && (
                    <>
                        <div className="flex gap-4 mt-4">
                            <div className="w-2/4">
                                <label htmlFor="icmsRate" className="flex text-roxo_oficial mt-1 gap-1 font-bold">
                                    Alíquota ICMS
                                </label>
                                <InputPercentage
                                    name="financial.icmsRate"
                                    id="icmsRate"
                                    value={values.financial.icmsRate}
                                    setFieldValue={setFieldValue}
                                    placeholder="Insira a alíquota ICMS"
                                />
                                <ErrorMessage
                                    component="p"
                                    name="financial.icmsRate"
                                    className="text-red text-xs font-light w-full"
                                />
                                <label htmlFor="discountValue" className="flex text-roxo_oficial mt-1 gap-1 font-bold">
                                    Valor do desconto
                                </label>
                                <InputPercentage
                                    name="financial.discountValue"
                                    id="discountValue"
                                    value={values.financial.discountValue}
                                    setFieldValue={setFieldValue}
                                    placeholder="Insira a porcentagem de desconto"
                                />
                                <ErrorMessage
                                    component="p"
                                    name="financial.discountValue"
                                    className="text-red text-xs font-light w-full"
                                />
                            </div>
                            <div className="w-2/4">
                                <label
                                    htmlFor="commercialDiscountAdditionalInformation"
                                    className="flex text-roxo_oficial gap-1 font-bold">
                                    Observações <span className="text-C5 font-normal ">(opcional)</span>
                                </label>
                                <Field
                                    as="textarea"
                                    rows="4"
                                    className="w-full rounded-md border py-1 px-2 border-C2 f"
                                    name="financial.commercialDiscountAdditionalInformation"
                                    id="commercialDiscountAdditionalInformation"
                                    onChange={handleChange}
                                    value={values.financial.commercialDiscountAdditionalInformation}
                                />
                            </div>
                        </div>
                    </>
                )}
                <div className="flex border-b border-C2 pb-2 mt-5 items-center">
                    <p className="text-C7 font-bold">Nota de Serviço</p>
                </div>
                <label className="text-roxo_oficial font-bold mt-4">Nota de Serviço</label>
                <Select
                    options={financialDetailsData}
                    name="financial.serviceInvoice"
                    className="outline-none transform-none text-black"
                    value={financialDetailsData.find(({ value }) => value === values.financial.serviceInvoice)}
                    placeholder="Selecione..."
                    onChange={value => setFieldValue("financial.serviceInvoice", value.value)}
                />
                <ErrorMessage component="p" name="financial.serviceInvoice" className="text-red text-xs font-light w-full" />
                {values.financial.serviceInvoice === true && (
                    <>
                        <div>
                            <div className=" grid grid-cols-3 gap-4">
                                <div>
                                    <label htmlFor="administrateTax" className="text-roxo_oficial font-bold mt-4">
                                        Nota de Serviço - Base de cálculo / Taxa administrativa
                                    </label>
                                    <InputPercentage
                                        name="financial.administrateTax"
                                        id="administrateTax"
                                        value={values.financial.administrateTax}
                                        setFieldValue={setFieldValue}
                                        placeholder="Insira o porcentagem da taxa administrativa"
                                    />
                                    <ErrorMessage
                                        component="p"
                                        name="financial.administrateTax"
                                        className="text-red text-xs font-light w-full"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="serviceInvoiceMinValue" className="text-roxo_oficial font-bold mt-4">
                                        Nota de Serviço - Valor mínimo
                                    </label>
                                    <InputCurrency
                                        name="financial.serviceInvoiceMinValue"
                                        id="serviceInvoiceMinValue"
                                        value={values.financial.serviceInvoiceMinValue}
                                        setFieldValue={setFieldValue}
                                        placeholder="Insira o valor mínimo"
                                    />
                                    <ErrorMessage
                                        component="p"
                                        name="financial.serviceInvoiceMinValue"
                                        className="text-red text-xs font-light w-full"
                                    />
                                </div>
                                <div>
                                    <label className="text-roxo_oficial font-bold mt-4">Nota de Serviço - Modalidade</label>
                                    <Select
                                        options={serviceInvoiceModalityData}
                                        name="financial.serviceInvoiceModality"
                                        className="outline-none transform-none text-black"
                                        value={serviceInvoiceModalityData.find(
                                            ({ value }) => value === values.financial.serviceInvoiceModality
                                        )}
                                        placeholder="Selecione..."
                                        onChange={value => setFieldValue("financial.serviceInvoiceModality", value.value)}
                                    />
                                    <ErrorMessage
                                        component="p"
                                        name="financial.serviceInvoiceModality"
                                        className="text-red text-xs font-light w-full"
                                    />
                                </div>
                            </div>
                            <div className="">
                                <label
                                    htmlFor="serviceInvoiceAdditionalInformation"
                                    className="flex text-roxo_oficial gap-1 font-bold mt-4">
                                    Observações <span className="text-C5 font-normal ">(opcional)</span>
                                </label>
                                <Field
                                    as="textarea"
                                    rows="4"
                                    className="w-full rounded-md border py-1 px-2 border-C2"
                                    name="financial.serviceInvoiceAdditionalInformation"
                                    id="serviceInvoiceAdditionalInformation"
                                    onChange={handleChange}
                                    value={values.financial.serviceInvoiceAdditionalInformation}
                                />
                            </div>
                        </div>
                    </>
                )}
                <div className="flex border-b border-C2 pb-2 mt-5 items-center">
                    <p className="text-C7 font-bold">Pagamento ao exportador</p>
                </div>
                <div className="flex gap-4 w-full">
                    <div className="w-1/2">
                        <label className="text-roxo_oficial font-bold mt-4">Pagamento ao Exportador</label>
                        <Select
                            options={exporterPaymentData}
                            name="financial.exporterPayment"
                            className="outline-none transform-none text-black"
                            isMulti
                            value={values.financial.exporterPayment}
                            placeholder="Selecione a forma de pagamento ao exportador"
                            onChange={value =>
                                value &&
                                setFieldValue(
                                    "financial.exporterPayment",
                                    value.map(item => item.value)
                                )
                            }
                        />
                        <ErrorMessage
                            component="p"
                            name="financial.exporterPayment"
                            className="text-red text-xs font-light w-full"
                        />
                    </div>
                    <div className="w-1/2">
                        <label
                            htmlFor="exporterPaymentAdditionalInformation"
                            className="flex text-roxo_oficial gap-1 font-bold mt-4">
                            Parecer de Crédito
                        </label>
                        <Field
                            as="textarea"
                            rows="4"
                            className="w-full rounded-md border py-1 px-2 border-C2"
                            name="financial.exporterPaymentAdditionalInformation"
                            id="exporterPaymentAdditionalInformation"
                            onChange={handleChange}
                            value={values.financial.exporterPaymentAdditionalInformation}
                        />
                        <ErrorMessage
                            component="p"
                            name="financial.exporterPaymentAdditionalInformation"
                            className="text-red text-xs font-light w-full"
                        />
                    </div>
                </div>
                <div className="flex border-b border-C2 pb-2 mt-4 items-center">
                    <p className="text-C7 font-bold">Encargos Financeiros</p>
                </div>
                <label className="text-roxo_oficial font-bold mt-3">Encargos Financeiros</label>
                <Select
                    options={financialDetailsData}
                    name="financial.financialCharges"
                    className="outline-none transform-none text-black"
                    value={financialDetailsData.find(({ value }) => value === values.financial.financialCharges)}
                    placeholder="Selecione..."
                    onChange={value => setFieldValue("financial.financialCharges", value.value)}
                />
                <ErrorMessage
                    component="p"
                    name="financial.financialCharges"
                    className="text-red text-xs font-light w-full"
                />
                {values.financial.financialCharges === true && (
                    <>
                        <div className="flex gap-4">
                            <div className="w-2/4">
                                <label className="text-roxo_oficial font-bold mt-4">Encargos Financeiros - Cálculo</label>
                                <Select
                                    options={financialChargesModalityData}
                                    name="financial.financialChargesModality"
                                    className="outline-none transform-none text-black"
                                    value={financialChargesModalityData.find(
                                        ({ value }) => value === values.financial.financialChargesModality
                                    )}
                                    placeholder="Selecione..."
                                    onChange={value => setFieldValue("financial.financialChargesModality", value.value)}
                                />
                                <ErrorMessage
                                    component="p"
                                    name="financial.financialChargesModality"
                                    className="text-red text-xs font-light w-full"
                                />
                                <label className="text-roxo_oficial font-bold mt-4">
                                    Encargos Financeiros - Valor adicional
                                </label>
                                <InputPercentage
                                    name="financial.financialChargesAddition"
                                    id="financialChargesAddition"
                                    value={values.financial.financialChargesAddition}
                                    setFieldValue={setFieldValue}
                                    placeholder="Insira a porcentagem do valor adicional"
                                />
                                <ErrorMessage
                                    component="p"
                                    name="financial.financialChargesAddition"
                                    className="text-red text-xs font-light w-full"
                                />
                            </div>
                            <div className="w-2/4">
                                <label
                                    htmlFor="financialChargesAdditionalInformation"
                                    className="flex text-roxo_oficial gap-1 font-bold mt-4">
                                    Observações <span className="text-C5 font-normal ">(opcional)</span>
                                </label>
                                <Field
                                    as="textarea"
                                    rows="4"
                                    className="w-full rounded-md border py-2 px-2 border-C2"
                                    name="financial.financialChargesAdditionalInformation"
                                    id="financialChargesAdditionalInformation"
                                    onChange={handleChange}
                                    value={values.financial.financialChargesAdditionalInformation}
                                />
                            </div>
                        </div>
                    </>
                )}
                <div className="flex border-b border-C2 pb-2 mt-4 items-center">
                    <p className="text-C7 font-bold">Condição de pagamento / Faturamento</p>
                </div>
                <div className="gap-4 grid grid-cols-4 items-start mt-4">
                    <div>
                        <label className="text-roxo_oficial font-bold">Duplicata Mercadoria</label>
                        <Select
                            options={paymentConditionInvoicingData}
                            name="financial.paymentTermsCommodity"
                            className="outline-none transform-none text-black"
                            value={paymentConditionInvoicingData.find(
                                ({ value }) => value === values.financial.paymentTermsCommodity
                            )}
                            placeholder="Selecione..."
                            onChange={value => setFieldValue("financial.paymentTermsCommodity", value)}
                        />
                        <ErrorMessage
                            component="p"
                            name="financial.paymentTermsCommodity"
                            className="text-red text-xs font-light w-full"
                        />
                    </div>
                    <div>
                        <label className="text-roxo_oficial font-bold ">Duplicata Impostos (II/IPI/Pis/Cofins/ICMS)</label>
                        <Select
                            options={paymentConditionInvoicingData}
                            name="financial.paymentTermsTax"
                            className="outline-none transform-none text-black"
                            value={paymentConditionInvoicingData.find(
                                ({ value }) => value === values.financial.paymentTermsTax
                            )}
                            placeholder="Selecione..."
                            onChange={value => setFieldValue("financial.paymentTermsTax", value)}
                        />
                        <ErrorMessage
                            component="p"
                            name="financial.paymentTermsTax"
                            className="text-red text-xs font-light w-full"
                        />
                    </div>
                    <div>
                        <label className="text-roxo_oficial font-bold">Duplicata Despesas + Custos Financeiros</label>
                        <Select
                            options={paymentConditionInvoicingData}
                            name="financial.paymentTermsExpenses"
                            className="outline-none transform-none text-black"
                            value={paymentConditionInvoicingData.find(
                                ({ value }) => value === values.financial.paymentTermsExpenses
                            )}
                            placeholder="Selecione..."
                            onChange={value => setFieldValue("financial.paymentTermsExpenses", value)}
                        />
                        <ErrorMessage
                            component="p"
                            name="financial.paymentTermsExpenses"
                            className="text-red text-xs font-light w-full"
                        />
                    </div>
                    <div>
                        <label className="text-roxo_oficial font-bold">Duplicata Nota de Serviço</label>
                        <Select
                            options={paymentConditionInvoicingData}
                            name="financial.paymentTermsServiceInvoice"
                            className="outline-none transform-none text-black"
                            value={paymentConditionInvoicingData.find(
                                ({ value }) => value === values.financial.paymentTermsServiceInvoice
                            )}
                            placeholder="Selecione..."
                            onChange={value => setFieldValue("financial.paymentTermsServiceInvoice", value)}
                        />
                        <ErrorMessage
                            component="p"
                            name="financial.paymentTermsServiceInvoice"
                            className="text-red text-xs font-light w-full"
                        />
                    </div>
                </div>
                <label htmlFor="paymentTermsAdditionalInformation" className="flex text-roxo_oficial gap-1 font-bold mt-4">
                    Observações <span className="text-C5 font-normal ">(opcional)</span>
                </label>
                <Field
                    as="textarea"
                    rows="4"
                    className="w-full rounded-md border py-1 px-2 border-C2"
                    name="financial.paymentTermsAdditionalInformation"
                    id="paymentTermsAdditionalInformation"
                    onChange={handleChange}
                    value={values.financial.paymentTermsAdditionalInformation}
                />
            </div>
        </div>
    );
};

export default FinancialDataForm;